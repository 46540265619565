import { feet } from "../data/feet";
import { Experience } from "./Enums";

export const FilterProducts = (
    weight: number,
    size: number,
    previousExperience: Experience
) => {
    return feet.filter(f => {
        return (
            f.minWeight <= weight &&
            f.maxWeight >= weight &&
            f.minSize <= size &&
            f.maxSize >= size
        );
    });
};
