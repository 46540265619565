import { Box, Flex } from "@chakra-ui/react";
import React, { useContext } from "react";
import { Foot } from "./../../utils/Interfaces";
import { FormContext } from "./../../utils/Context";
import { useTranslation } from "react-i18next";
import { FootDescription } from "./FootDescription";
import { GetHeight } from "./../../utils/GetHeight";
interface Props {
    data: Foot;
    index: number;
}


export const Model = ({ data, index }: Props) => {
    const {
        inputValues: { size },
    } = useContext(FormContext);

    const { t } = useTranslation();
    return (
        <Box
            w={{
                base: "100%",
                sm: "100%",
                md: "100%",
                lg: "70%",
                xl: "70%",
            }}
            h={{
                base: "auto",
                sm: "auto",
                md: "auto",
                lg: "170px",
                xl: "170px",
            }}
            boxShadow="0 10px 16px 0 rgba(0,0,0,0.2),0 6px 20px 0 rgba(0,0,0,0.19)!important"
            rounded="lg"
            my={4}
            p={4}
            m="auto"
            display="flex"
            alignItems="center"
        >
            <Flex flexDirection="column">
                <Box>
                    <Box
                        mt="1"
                        fontWeight="semibold"
                        as="h1"
                        lineHeight="tight"
                        isTruncated
                    >
                        {data.id.replace("#size#", size!.toString())}
                    </Box>
                </Box>
                <Box
                    color="grey.500"
                    fontWeight="semibold"
                    letterSpacing="wide"
                    fontSize="xs"
                    textTransform="uppercase"
                >
                    {t("RightAndLeft")}
                </Box>
                <Box
                    color="grey.500"
                    fontWeight="semibold"
                    letterSpacing="wide"
                    fontSize="xs"
                    textTransform="uppercase"
                >
                    {t("height")} {GetHeight(data.height, size!)} mm
                </Box>
                <FootDescription index={index} />
            </Flex>
        </Box>
    );
};
