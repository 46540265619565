export enum ValueTypes {
    Weight = "weigth",
    Size = "size",
}

export enum Lang {
    Standard = "en",
    Swedish = "sv",
    French = "fr",
    Deutch = "de",
    Japanese = "jp",
    Norweigan = "no",
    American = "us",
}

export enum Experience {
    NotApplicable = "N/A",
    Soft = "soft",
    Stiff = "stiff",
}
