import { createContext } from "react";
import { IFormContext, IStateProps, Values } from "./Interfaces";

export const FormContext = createContext<IFormContext>({
    inputValues: {
        weight: undefined,
        size: undefined,
        model: "",
        activity: "",
        selected: false,
    },
    changeValue: (values: Values) => {},
});

export const CreateInitalFormState = (state: IStateProps<Values>) => {
    return {
        inputValues: state.state,
        changeValue: (values: Values) => {
            state.setState(values);
        },
    };
};
