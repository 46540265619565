import { Box, Flex, Image } from "@chakra-ui/react";
import * as React from "react";
import XtendLogo from "../assets/lindhextend.svg";
import { LangSelector } from "./LangSelector";

export default function Navbar() {
    return (
        <nav>
            <Flex
                justifyContent="space-between"
                alignItems="center"
                h="calc(var(--vh) * 8)"
                bg="lindhe.100"
            >
                <Box
                    w="20vh"
                    h="100%"
                />
                <Box height="100%" w="100%">
                    <Image
                        p="1rem"
                        mx="auto"
                        height="calc(var(--vh) * 8)"
                        src={XtendLogo}
                        alt="Lindhe Xtend"
                    />
                </Box>
                <Flex h="calc(var(--vh) * 8)" w="20vh" alignItems="center" justifyContent="center">
                    <LangSelector />
                </Flex>
                    {/* <Button
                        bg="teal"
                        variant="ghost"
                        h="80%"
                        w={{
                            base: "8.5vh",
                            sm: "8.5vh",
                            md: "8.5vh",
                            lg: "10vh",
                            xl: "10vh",
                        }}
                        onClick={() => {
                            i18n.changeLanguage("sv");
                        }}
                    >
                        <FlagUK />
                    </Button>
                    <Button
                        bg="red"
                        variant="ghost"
                        h="80%"
                        w={{
                            base: "8.5vh",
                            sm: "8.5vh",
                            md: "8.5vh",
                            lg: "10vh",
                            xl: "10vh",
                        }}
                        onClick={() => {
                            i18n.changeLanguage("en");
                        }}
                    >
                        <FlagUS />
                    </Button> */}
            </Flex>
        </nav>
    );
}
