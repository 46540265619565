import { Badge, Box } from "@chakra-ui/react";
import React, { useContext } from "react";
import { FormContext } from "../../utils/Context";
import { useTranslation } from "react-i18next";
import { Experience } from "../../utils/Enums";

interface Props {
    index: number;
}
export const FootDescription = ({ index }: Props) => {
    const { t } = useTranslation();
    const descriptions = [
        {
            desc: t("desc1"),
            color: "Green",
        },
        {
            desc: t("desc2"),
            color: "Yellow",
        },
        {
            desc: t("desc3"),
            color: "Grey",
        },
        {
            desc: t("desc4"),
            color: "Grey",
        },
    ];
    let data = descriptions[index];
    const { inputValues } = useContext(FormContext);
    if (!data) {
        data = descriptions[4];
    }
    const highActivity =
        inputValues.model === "TT" &&
        inputValues.activity === "High" &&
        inputValues.previousExperience !== Experience.Soft;
    const stiffFoot =
        highActivity || inputValues.previousExperience === Experience.Stiff;
    if (index === 0 && !stiffFoot) {
        return (
            <>
                <Badge mt={4} variant="solid" colorScheme="green">
                    {t("recommended")}
                </Badge>
                <Box
                    color="grey.500"
                    fontWeight="semibold"
                    letterSpacing="wide"
                    fontSize="xs"
                    borderRadius={6}
                    p={1}
                >
                    {data.desc}
                </Box>
            </>
        );
    }

    if (index === 1 && stiffFoot) {
        return (
            <>
                <Badge mt={4} variant="solid" colorScheme="green">
                    {t("recommended")}
                </Badge>
                <Box
                    color="grey.500"
                    fontWeight="semibold"
                    letterSpacing="wide"
                    fontSize="xs"
                    borderRadius={6}
                    p={1}
                >
                    {data.desc}
                </Box>
            </>
        );
    }

    return (
        <Box
            color="grey.500"
            fontWeight="semibold"
            letterSpacing="wide"
            fontSize="xs"
            borderRadius={6}
            p={1}
            mt={4}
        >
            {data.desc}
        </Box>
    );
};
