import { Flex, extendTheme, ChakraProvider, Text } from "@chakra-ui/react";
import React, { useState, useEffect } from "react";
import { ModelDisplay } from "./components/DisplayModels/ModelDisplay";
import Navbar from "./components/Navbar";
import { Placeholder } from "./components/Placeholder";
import { FormikSelect } from "./components/SelectValues/FormikSelect";
import { CreateInitalFormState, FormContext } from "./utils/Context";
import { Values } from "./utils/Interfaces";
import { useTranslation } from 'react-i18next';

const theme = extendTheme({
    colors: {
        lindhe: {
            100: "#E85829",
            500: "#E85829",
            600: "#4a4a4a",
            800: "#FFF",
        },
    },
});
const App = () => {
    const { t } = useTranslation();
    useEffect(() => {
        let vh = window.innerHeight * 0.01;
        document.documentElement.style.setProperty('--vh', vh + 'px');
    }, [])
    const [values, setValues] = useState<Values>({
        weight: undefined,
        size: undefined,
        selected: false,
        model: "",
        activity: "",
    });

    const state = CreateInitalFormState({ state: values, setState: setValues });

    return (
        <ChakraProvider theme={theme}>
            <Navbar />
            <FormContext.Provider value={state}>
                <Flex
                    flexDirection={{
                        base: "column",
                        sm: "column",
                        md: "column",
                        lg: "row",
                        xl: "row",
                    }}
                >
                    <Flex
                        h={{
                            base: "100%",
                            sm: "100%",
                            md: "100%",
                            lg: "92vh",
                            xl: "92vh",
                        }}
                        w={{
                            base: "100%",
                            sm: "100%",
                            md: "100%",
                            lg: "33%",
                            xl: "33%",
                        }}
                        bg="#ebebeb"
                        pb={{
                            base: "10vh",
                            sm: "10vh",
                            md: "10vh",
                            lg: "0",
                            xl: "0",
                        }}
                        position="relative"
                    >
                        <FormikSelect />
                        <Flex
                            position="absolute"
                            bottom="5px"
                            w="100%"
                            alignItems="center"
                            justifyContent="center"
                        >
                            <Text
                                fontSize="sm"
                                textAlign="center"
                                opacity={0.8}
                            >
                                {t("NotIntended")}
                            </Text>
                        </Flex>
                    </Flex>
                    {!values.selected ? <Placeholder /> : <ModelDisplay />}
                </Flex>
            </FormContext.Provider>
        </ChakraProvider>
    );
};

export default App;

