import { ArrowBackIcon, ArrowForwardIcon } from "@chakra-ui/icons";
import { Flex, Image, Spinner } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
interface Props {
    index: number;
}

export const ModelImage = ({ index }: Props) => {
    const Images = [
        "labels/camera1.png",
        "labels/camera2.png",
        "labels/camera3.png",
        "labels/camera4.png",
        "labels/camera5_2.png",
        "labels/camera5.png",
        "labels/camera6.png",
        "labels/camera7.png",
        "labels/camera7_2.png",
        "labels/camera9.png",
        "labels/skiss.png",
    ];
    const [idx, setIdx] = useState(index);
    const NextImage = () => {
        if (idx === Images.length - 1) setIdx(0);
        else setIdx(idx + 1);
    };
    const LastImage = () => {
        if (idx === 0) setIdx(Images.length - 1);
        else setIdx(idx - 1);
    };
    useEffect(() => {
        const interval = setInterval(() => {
            if (idx === Images.length - 1) setIdx(0);
            else setIdx(idx + 1);
        }, 8000);
        return () => clearInterval(interval);
    }, [Images.length, idx]);
    return (
        <Flex
            w={{
                base: "100%",
                sm: "100%",
                md: "100%",
                lg: "82%",
                xl: "82%",
            }}
            h={{
                base: "15vh",
                sm: "15vh",
                md: "15vh",
                lg: "245px",
                xl: "250px",
            }}
            alignItems="center"
            justifyContent="space-between"
        >
            <ArrowBackIcon
                w={10}
                h={10}
                cursor="pointer"
                onClick={LastImage}
                _hover={{ color: "#38A169" }}
            />
            <Image
                w="50%"
                minW="50%"
                h="100%"
                objectFit="contain"
                maxH="100%"
                py="10px"
                src={Images[idx]}
                alt="LindheXtend Foot"
                fallback={
                    <Flex
                        alignItems="center"
                        justifyContent="center"
                        w="50%"
                        h="100%"
                        minW="50%"
                        minH="100%"
                        py="10px"
                        color="lindhe.100"
                    >
                        <Spinner size="xl" />
                    </Flex>
                }
            />
            <ArrowForwardIcon
                w={10}
                h={10}
                cursor="pointer"
                onClick={NextImage}
                _hover={{ color: "#38A169" }}
            />
        </Flex>
    );
};
