import { Flex, Image, Heading, Text, Fade } from '@chakra-ui/react';
import * as React from 'react';
import { useTranslation } from 'react-i18next';

export const Placeholder = () => {
    const { t } = useTranslation();
    return (
        <Flex
            h="92vh"
            w={{
                base: '100%',
                sm: '100%',
                md: '100%',
                lg: '67%',
                xl: '67%',
            }}
            justifyContent="center"
            alignItems="center"
            flexDirection="column"
            backgroundImage="url('Konfiguratorbild.jpg')"
            backgroundSize="cover"
            backgroundPosition={{
                base: '78% 78%',
                sm: '78% 78%',
                md: '78% 78%',
                lg: 'unset',
                xl: 'unset',
            }}
            backgroundRepeat="no-repeat"
        >
            <Fade in={true}>
                <Flex
                    maxW="70%"
                    mx="auto"
                    justifyContent="center"
                    alignItems="center"
                    textAlign="center"
                    flexDirection="column"
                >
                    <Image
                        src="Lindhe_Xtend_X.png"
                        alt="Lindhe Xtend X"
                        w="120px"
                    ></Image>
                    <Heading
                        as="h2"
                        size="xl"
                        marginBottom="0.5rem"
                        color="white"
                    >
                        {t('XtendFootTool')}
                    </Heading>
                    <Text
                        color="white"
                        textAlign="center"
                        marginBottom="0.5rem"
                    >
                        {t('AssureFoot')}
                    </Text>
                    <Heading
                        as="h3"
                        size="lg"
                        color="white"
                        onClick={() => window.scrollTo(0, 0)}
                        marginBottom="0.5rem"
                    >
                        {t('GetStarted')}
                    </Heading>
                </Flex>
            </Fade>
        </Flex>
    );
};
