const feet: Foot[] =
    [
        {
            "id": "H100-3#size#",
            "minWeight": 40,
            "maxWeight": 60,
            "minSize": 23,
            "maxSize": 24,
            "height": [
                [23, 95],
                [24, 95]
            ],
            "description": "Optimal inversion/eversion dynamic function for surface accommodation",
            "color": "Green"
        },
        {
            "id": "H100-4#size#",
            "minWeight": 40,
            "maxWeight": 80,
            "minSize": 23,
            "maxSize": 29,
            "height": [
                [23, 95],
                [24, 96],
                [25, 104],
                [26, 106],
                [27, 106],
                [28, 109],
                [29, 109]
            ],
            "description": "Optimal inversion/eversion dynamic function for surface accommodation",
            "color": "Green"
        },
        {
            "id": "H100-5#size#",
            "minWeight": 40,
            "maxWeight": 100,
            "minSize": 23,
            "maxSize": 29,
            "height": [
                [23, 96],
                [24, 97],
                [25, 105],
                [26, 106],
                [27, 107],
                [28, 110],
                [29, 111]
            ],
            "description": "Increased resistance for higher energy return while still maintaining exceptional side-to-side flexibility",
            "color": "Yellow"
        },
        {
            "id": "H100-6#size#",
            "minWeight": 40,
            "maxWeight": 125,
            "minSize": 25,
            "maxSize": 29,
            "height": [
                [25, 106],
                [26, 107],
                [27, 107],
                [28, 112],
                [29, 112]
            ],
            "description": "Maximum rigidity, loss of some medial/lateral ground compliance",
            "color": "Grey"
        },
        {
            "id": "H100-7#size#",
            "minWeight": 40,
            "maxWeight": 150,
            "minSize": 26,
            "maxSize": 29,
            "height": [
                [26, 108],
                [27, 108],
                [28, 113],
                [29, 113]
            ],
            "description": "Exaggerated stiffness",
            "color": "Grey"
        }
    ];

export {
    feet
};