import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import languageEN from "./localization/en.json";
import languageSV from "./localization/sv.json";
import languageNO from "./localization/no.json";
import languageJA from "./localization/ja.json";
import languageFR from "./localization/fr.json";
import languageUS from "./localization/us.json";
import languageDE from "./localization/de.json";

const detectionOptions = {
    order: ['path'],
    lookupFromPathIndex: 0
}
i18n.use(LanguageDetector)
    .use(initReactI18next)
    .init({
        // we init with resources
        resources: {
            en: languageEN,
            sv: languageSV,
            de: languageDE,
            us: languageUS,
            fr: languageFR,
            jp: languageJA,
            no: languageNO,
        },
        fallbackLng: "en",
        debug: false,
        detection: detectionOptions,
        // have a common namespace used around the full app
        ns: ["translations"],
        defaultNS: "translations",
        keySeparator: false, // we use content as keys
        interpolation: {
            escapeValue: false, // not needed for react!!
            formatSeparator: ",",
        },
        react: {
            wait: true,
        },
    });

export default i18n;
