export const KiloToPounds = (kilo: number | undefined): number | undefined => {
    if (!kilo) {
        return undefined;
    }
    const lb = Math.round(kilo * 2.20462262);
    return lb;
};

export const PoundsToKilo = (lb: number): number => {
    const kg = Math.round(lb / 2.20462262);
    return kg;
};
