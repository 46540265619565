import {
    Box,
    Button,
    Flex,
    Grid,
    ScaleFade,
    Image,
    Text,
} from '@chakra-ui/react';
import React, { useContext, useEffect, useMemo, useRef } from 'react';
import { Model } from './Model';
import { FormContext } from './../../utils/Context';
import { FilterProducts } from './../../utils/FilterProducts';
import { useTranslation } from 'react-i18next';
import { ModelImage } from './ModelImage';

export const ModelDisplay = () => {
    const { inputValues, changeValue } = useContext(FormContext);
    const { t } = useTranslation();
    const feet: Foot[] = useMemo(
        () =>
            FilterProducts(
                inputValues.weight!,
                inputValues.size!,
                inputValues.previousExperience!
            ),
        [inputValues.weight, inputValues.size, inputValues.previousExperience]
    );
    const previewEl = useRef<HTMLDivElement>(null);
    const missingEl = useRef<HTMLDivElement>(null);
    useEffect(() => {
        if (previewEl && previewEl.current && window.innerWidth < 1000) {
            previewEl.current.scrollIntoView({ behavior: 'smooth' });
        } else if (missingEl && missingEl.current) {
            missingEl.current.scrollIntoView({ behavior: 'smooth' });
        }
    }, [inputValues]);
    if (feet.length === 0) {
        return (
            <Flex
                w="100%"
                height="100%"
                minHeight="170px"
                direction="column"
                alignItems="center"
                justifyContent="center"
                ref={missingEl}
            >
                <h1>{t('NoProductsAvailable')}</h1>
                <Button
                    variant="solid"
                    bg="lindhe.100"
                    onClick={() => {
                        changeValue({
                            size: inputValues.size,
                            weight: inputValues.weight,
                            selected: false,
                            model: '',
                            activity: '',
                        });
                    }}
                >
                    {t('TryAgain')}
                </Button>
            </Flex>
        );
    }

    return (
        <Box
            w={{
                base: '100%',
                sm: '100%',
                md: '100%',
                lg: '75%',
                xl: '75%',
            }}
            position="relative"
        >
            <ScaleFade in={true} initialScale={0.8}>
                <Flex
                    overflow="auto"
                    w="100%"
                    h={{
                        base: '100%',
                        sm: '100%',
                        md: '100%',
                        lg: '92vh',
                        xl: '92vh',
                    }}
                    direction="column"
                    alignItems="center"
                    py={10}
                    px={8}
                    ref={previewEl}
                >
                    <ModelImage index={0} />
                    <Grid
                        templateColumns="repeat(auto-fit, minmax(min(445px, 100%), 1fr))"
                        w="100%"
                        alignItems="center"
                        justifyContent="center"
                        gap={4}
                    >
                        {feet.map((f, idx) => {
                            return <Model key={f.id} data={f} index={idx} />;
                        })}
                    </Grid>
                    <Flex
                        w="100%"
                        maxWidth="400px"
                        mt={10}
                        justifyContent="center"
                        align="center"
                    >
                        <Image
                            src="utropstecken.svg"
                            alt="Exlamation mark"
                            height="40px"
                            mr={4}
                        />

                        <Text fontSize="sm" textAlign="center" opacity={1}>
                            {t('IncreaseWeightForActivity')}
                        </Text>
                    </Flex>
                    <Flex
                        w="100%"
                        maxWidth="400px"
                        mt={10}
                        justifyContent="space-around"
                    >
                        <Button
                            bg="lindhe.500"
                            color="white"
                            as="a"
                            href={t('GoToLink')}
                            target="blank"
                            _hover={{ color: '#faa183' }}
                            display="flex"
                            alignItems={'center'}
                            justifyContent={'center'}
                        >
                            {t('GoToProductPage')}
                        </Button>
                        <Button
                            bg="lindhe.500"
                            color="white"
                            as="a"
                            href={t('ContactLink')}
                            _hover={{ color: '#faa183' }}
                            display="flex"
                            alignItems={'center'}
                            justifyContent={'center'}
                        >
                            {t('ContactUs')}
                        </Button>
                    </Flex>
                </Flex>
            </ScaleFade>
        </Box>
    );
};
