import React from "react";
import Flag from "react-world-flags";
import { Lang } from "../utils/Enums";
import { Globe } from "./Globe";
import { Box } from "@chakra-ui/react";

interface Props {
    langKey: string;
}

export const CorrectFlag = ({ langKey }: Props): JSX.Element => {
    if (langKey === Lang.Swedish)
        return (
            <Box w="50%">
                <Flag code={"se"} height="16" />
            </Box>
        );
    if (langKey === Lang.Norweigan)
        return (
            <Box w="50%">
                <Flag code={"NO"} height="16" />
            </Box>
        );
    if (langKey === Lang.American)
        return (
            <Box w="50%">
                <Flag code={"us"} height="16" />
            </Box>
        );
    if (langKey === Lang.Deutch)
        return (
            <Box w="50%">
                <Flag code={"DEU"} height="16" />
            </Box>
        );
    if (langKey === Lang.Japanese)
        return (
            <Box w="50%">
                <Flag code={"JPN"} height="16" />
            </Box>
        );
    if (langKey === Lang.French)
        return (
            <Box w="50%">
                <Flag code={"FRA"} height="16" />
            </Box>
        );
    return <Globe />;
};
